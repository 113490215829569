var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { attrs: { readonly: !_vm.canEditSettings } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "px-2 bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-subheader", [
                            _vm._v("Default sort for Locations:"),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.other.locationSort,
                                callback: function ($$v) {
                                  _vm.$set(_vm.other, "locationSort", $$v)
                                },
                                expression: "other.locationSort",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Code", value: "code" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Name", value: "name" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "px-2", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              "Location to be used to determine the zone of a trip:"
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.other.determineZoneBy,
                                callback: function ($$v) {
                                  _vm.$set(_vm.other, "determineZoneBy", $$v)
                                },
                                expression: "other.determineZoneBy",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "Request Location",
                                  value: "request",
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "Vehicle Location",
                                  value: "vehicle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "px-2 bg-blue", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              "Require attachment for the following trip and vehicle types:"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-subheader", [_vm._v("Trip Types")]),
                          _c(
                            "div",
                            { staticClass: "checkbox-wrapper" },
                            _vm._l(_vm.tripTypes, function (type, i) {
                              return _c("v-checkbox", {
                                key: i,
                                staticClass: "checkbox",
                                attrs: {
                                  value: type.id,
                                  label: type.name,
                                  multiple: "",
                                },
                                model: {
                                  value: _vm.other.requireAttachmentsTripTypes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.other,
                                      "requireAttachmentsTripTypes",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "other.requireAttachmentsTripTypes",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-subheader", [_vm._v("Vehicle Types")]),
                          _c(
                            "div",
                            { staticClass: "checkbox-wrapper" },
                            _vm._l(_vm.vehicleTypes, function (type, i) {
                              return _c("v-checkbox", {
                                key: i,
                                staticClass: "checkbox",
                                attrs: {
                                  value: type.id,
                                  label: type.name,
                                  multiple: "",
                                },
                                model: {
                                  value:
                                    _vm.other.requireAttachmentsVehicleTypes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.other,
                                      "requireAttachmentsVehicleTypes",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "other.requireAttachmentsVehicleTypes",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }